<template>
  <div class="custom-container">
    <el-row v-if="!$route.query.noReturn">
      <el-page-header @back="goBack" content="修改委托单"></el-page-header>
    </el-row>
    <el-row>
      <div class="order-content">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <h4>基本信息</h4>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item
                label="订单编号"
                prop="businessNumber"
                label-width="90px"
              >
                <el-input
                  v-model="form.businessNumber"
                  placeholder="请输入"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="计划单号"
                prop="businessNumber"
                label-width="90px"
              >
                <el-input
                  v-model="form.planNumber"
                  placeholder="请输入"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="运输方式"
                prop="shippingMethodId"
                label-width="90px"
              >
                <el-select
                  v-model="form.shippingMethodId"
                  placeholder="请选择"
                  clearable
                  @change="(val) => onShippingMethodChange(val)"
                >
                  <el-option
                    v-for="item in transpotwayList"
                    :key="item.freightSettingId"
                    :label="item.transportWayCn"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="贸易条款" prop="tradeId" label-width="90px">
                <el-select
                  v-model="form.tradeId"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in trades"
                    :key="item.amountId"
                    :label="item.amountName"
                    :value="item.amountId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item
                label="发货地点"
                prop="portShipment"
                label-width="90px"
              >
                <el-input
                  v-model="form.portShipment"
                  placeholder="请输入"
                  maxlength="50"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 单证员不可编辑 -->
              <el-form-item label="单证员" label-width="90px">
                <el-input
                  placeholder="请选择"
                  @focus="showUserTree"
                  disabled
                  v-model="form.clerkName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="display: flex;align-items: center;">
              <el-form-item
                label="客户交期"
                prop="deliveryDate"
                label-width="90px"
                style="flex: 1"
              >
                <el-date-picker
                  v-model="form.deliveryDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <!-- 是否显示客户交期后面的Firm 交期  1=显示 0=不显示 -->
              <el-tag v-if="detailInfo.firmFlag == 1" size="mini" type="warning" effect="dark" class="ml10">Firm 交期</el-tag>
            </el-col>

            <el-col :span="6" style="display: flex;align-items: center;">
              <el-form-item
                label="装运日期"
                prop="shippingDate"
                label-width="90px"
                clearable
              >
                <el-date-picker
                  v-model="form.shippingDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-button type="primary" size="mini" class="ml10" @click="handleSetShippingDate(1)">明天</el-button>
              <el-button size="mini" @click="handleSetShippingDate(2)">后天</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <!-- 国内物流 -->
            <el-col :span="6">
              <el-form-item
                label="国内物流"
                prop="domesticLogistics"
                label-width="90px"
              >
                <el-select
                  v-model="form.domesticLogistics"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in domesticLogisticsList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 快递单号 -->
            <el-col :span="6" v-if="form.domesticLogistics === 0">
              <el-form-item
                label="快递单号"
                prop="trackingNo"
                label-width="90px"
              >
                <el-input
                  v-model="form.trackingNo"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 垫付运费 -->
            <el-col :span="6">
              <el-form-item
                label="垫付运费"
                prop="advanceFreight"
                label-width="90px"
              >
                <el-select
                  v-model="form.advanceFreight"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in advanceFreightList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="客户名称"
                prop="customerName"
                label-width="90px"
              >
                <el-input
                  placeholder="请输入"
                  disabled
                  v-model="form.customerName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item
                label="客户代表"
                label-width="90px"
                prop="businessName"
              >
                <el-input
                  disabled
                  v-model="form.businessName"
                  @focus="showUserDialog"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="创建人"
                label-width="90px"
                prop="creatorName"
              >
                <el-input v-model="form.creatorName" disabled></el-input>
              </el-form-item>
            </el-col>
            <template
              v-if="
                canSelectAutoEmail &&
                form.shippingMethodId &&
                ingoreShippingMethodList.includes(
                  form.shippingMethod.toUpperCase()
                )
              "
            >
              <el-col :span="12">
                <el-form-item label-width="28px">
                  自动发送物流动态邮件,
                  <span class="page-link text-left" @click="handleView">
                    点击查看示例
                  </span>
                  <ErpMouseoverTip effect="dark" placement="bottom">
                    <div>
                      <p class="info-text">
                        1. 仅支持部分运输方式，并以实际出运时的运输方式为准；
                      </p>
                      <p class="info-text">
                        2. 若手动发送了发货邮件，系统将不再自动发送发货邮件；
                      </p>
                    </div>
                  </ErpMouseoverTip>
                </el-form-item>
              </el-col>
            </template>
            <el-col :span="24" class="mt20">
              <el-form-item label="备注" label-width="90px">
                <el-input
                  type="textarea"
                  v-model="form.remarks"
                  maxlength="500"
                  placeholder="请输入内容，字数500字以内"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="mt20"></el-row>
          <h4></h4>
          <div class="c-table-wrap">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>
                    <span>
                      <i>*</i>
                      国家
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收货地址
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收货人
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      收货公司
                    </span>
                  </th>
                  <th>
                    <span>
                      <i>*</i>
                      联系电话
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <el-form-item prop="country">
                      <el-input
                        v-model="form.country"
                        placeholder="请输入"
                        maxlength="250"
                        @blur="checkBlack(form.country)"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="receivingAddress">
                      <el-input
                        v-model="form.receivingAddress"
                        @blur="updateReceivingAddress(form.receivingAddress)"
                        placeholder="请输入"
                        maxlength="250"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="receivingPerson">
                      <el-input
                        v-model="form.receivingPerson"
                        placeholder="请输入"
                        maxlength="50"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="receivingCompany">
                      <el-input
                        v-model="form.receivingCompany"
                        placeholder="请输入"
                        maxlength="50"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item prop="receivingPhone">
                      <el-input
                        v-model="form.receivingPhone"
                        placeholder="请输入"
                        maxlength="50"
                      ></el-input>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 style="display: inline-block">出运产品</h4>
          <div class="c-table-wrap">
            <el-table
              :key="tableKey"
              stripe
              border
              :data="form.shippingOrderProductUpdateVOS"
              :span-method="objectSpanMethod"
            >
              <el-table-column
                label="产品图片"
                align="center"
                prop=""
                width="120px"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tag
                      size="mini"
                      class="el-tag-other"
                      v-if="scope.row.sampleFlag"
                    >
                      {{ $t('AddOrder.PrenatalSample') }}
                    </el-tag>
                    <erp-image :imgs="scope.row.productImg"></erp-image>
                    <popover-ship
                      :itemTitle="
                        scope.row.mainFlag == 1 && scope.row.splitFlag == 1
                          ? '主'
                          : scope.row.mainFlag == 0
                          ? '子'
                          : ''
                      "
                      :tableData="
                        scope.row.mainFlag == 0
                          ? scope.row.parentProduct
                          : scope.row.childProductList
                      "
                      triggerType="click"
                    ></popover-ship>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="SKU ID"
                align="center"
                prop=""
                width="130"
              >
                <template slot-scope="scope">
                  <span @click="showProDetail(scope.row)" class="page-link">
                    {{ scope.row.sku }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="中文报关名/英文报关名"
                align="center"
                width="200px"
                prop=""
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.declareCn || scope.row.productCn }}/{{
                      scope.row.declareEn || scope.row.productEn
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="规格" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.productSpecs ? scope.row.productSpecs : '--' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="产品材质" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.productMaterial
                        ? scope.row.productMaterial
                        : '--'
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="订单数量" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.totalNum ? scope.row.totalNum : '--' }}
                  </span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="待出运数量"
                align="center"
                width="100px"
                prop=""
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.unShippedNum > 0 ? scope.row.unShippedNum : '--'
                    }}
                  </span>
                </template>
              </el-table-column> -->
              <el-table-column
                label="申报单价"
                align="center"
                prop=""
                width="150px"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'shippingOrderProductUpdateVOS.' +
                      scope.$index +
                      '.declaredUnitPrice'
                    "
                    :rules="[
                      {
                        validator: (rule, value, callback) => {
                          value = value + ''
                          value = value.split('.')[0]
                          if (value && value.length > 10) {
                            callback(new error('最大10位'))
                          } else {
                            callback()
                          }
                        },
                        message: '最大10位',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input-number
                      v-model="scope.row.declaredUnitPrice"
                      :controls="false"
                      controls-position="right"
                      :precision="3"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="买方" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.purchaser || '--' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="是否退税"
                align="center"
                prop=""
                width="120px"
              >
                <div class="selectFlex" slot-scope="scope">
                  <!-- 出库状态为“未出库”时，是否退税需要禁用 -->
                  <el-select v-model="scope.row.drawback" placeholder="请选择" :disabled="scope.row.shippingOrderProductStatus == 6">
                    <el-option
                      v-for="item in drawbacks"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                  <ErpMouseoverTip
                    v-if="scope.row.fromSale == 1 && scope.row.drawback != scope.row.orgDrawback"
                    effect="dark"
                  >
                    你已修改此选项，且与采购订单上的「是否退税」选项不一致，请仔细核对
                  </ErpMouseoverTip>
                </div>
              </el-table-column>
              <el-table-column label="唛头" align="center" prop="">
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="setMark(scope.row)"
                  >
                    点击查看
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column align="center" width="170px">
                <template slot="header">
                  <span class="required">*</span>
                  <span>装箱尺寸(CM)</span>
                </template>
                <template slot-scope="scope">
                  <div
                    @mousemove="toggleEdit(scope.$index, true)"
                    @mouseleave="toggleEdit(scope.$index, false)"
                  >
                    <PackingSize
                      :ref="scope.$index + 'packingSize'"
                      :packingSizes="scope.row.shippingOrderProductBoxVOS"
                      showEdit
                      @set-sizes="(val) => setSizes(scope.$index, val)"
                    />
                    <p
                      v-if="
                        showRequired &&
                        scope.row.shippingOrderProductBoxVOS.length === 0
                      "
                      style="color: red"
                    >
                      请选择
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="总箱数"
                align="center"
                prop=""
                width="150px"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'shippingOrderProductUpdateVOS.' +
                      scope.$index +
                      '.stockCaseNum'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '总箱数必填',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value < 0) {
                            callback(new error('总箱数为正整数'))
                          } else {
                            callback()
                          }
                        },
                        message: '总箱数为正整数',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input-number
                      v-model="scope.row.stockCaseNum"
                      :controls="false"
                      placeholder="请输入"
                      controls-position="right"
                      :precision="0"
                      @change="onFieldChange(scope.row, 'stockCaseNum')"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="总重量"
                align="center"
                prop=""
                width="150px"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'shippingOrderProductUpdateVOS.' +
                      scope.$index +
                      '.stockGrossWeight'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '总重量必填',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value < 0) {
                            callback(new error('总重量为正数'))
                          } else {
                            callback()
                          }
                        },
                        message: '总重量为正数',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          value = value + ''
                          value = value.split('.')[0]
                          if (value && value.length > 10) {
                            callback(new error('最大10位'))
                          } else {
                            callback()
                          }
                        },
                        message: '最大10位',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input-number
                      v-model="scope.row.stockGrossWeight"
                      :controls="false"
                      controls-position="right"
                      placeholder="请输入"
                      :precision="2"
                      @change="onFieldChange(scope.row, 'stockGrossWeight')"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="总体积"
                align="center"
                prop=""
                width="150px"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'shippingOrderProductUpdateVOS.' +
                      scope.$index +
                      '.stockVolume'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '总体积必填',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value < 0) {
                            callback(new error('总体积为正数'))
                          } else {
                            callback()
                          }
                        },
                        message: '总体积为正数',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          value = value + ''
                          value = value.split('.')[0]
                          if (value && value.length > 10) {
                            callback(new error('最大10位'))
                          } else {
                            callback()
                          }
                        },
                        message: '最大10位',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input-number
                      v-model="scope.row.stockVolume"
                      :controls="false"
                      controls-position="right"
                      placeholder="请输入"
                      :precision="2"
                      @change="onFieldChange(scope.row, 'stockVolume')"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="出库单号"
                align="center"
                prop=""
                width="120px"
              >
                <template slot-scope="scope">
                  <div>
                    <el-link
                      type="primary"
                      :underline="false"
                      v-if="scope.row.warehouseOutNumber"
                      @click="ckClick(scope.row.warehouseOutNumber)"
                    >
                      {{ scope.row.warehouseOutNumber }}
                    </el-link>
                    <span v-else>--</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="出库仓库" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.warehouseName ? scope.row.warehouseName : '--'
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="出库状态" align="center" prop="">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.shippingOrderProductStatus | stateFilter }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="出运数量"
                align="center"
                prop=""
                width="150px"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'shippingOrderProductUpdateVOS.' +
                      scope.$index +
                      '.warehouseOutNum'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '出运数量必填',
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value <= 0) {
                            callback(new error('出运数量为正整数'))
                          } else {
                            callback()
                          }
                        },
                        message: '出运数量为正整数',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input-number
                      v-model="scope.row.warehouseOutNum"
                      :controls="false"
                      placeholder="请输入"
                      controls-position="right"
                      :precision="0"
                      :max="scope.row.maxShippNum"
                      disabled
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" prop="">
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="删除"
                    placement="top-start"
                  >
                    <el-button
                      type="danger"
                      plain
                      icon="el-icon-delete-solid"
                      circle
                      size="mini"
                      @click="beforeDelete(scope.$index)"
                    ></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <h4>内部备注</h4>
          <el-input
            disabled
            type="textarea"
            v-model="form.insideRemarks"
            maxlength="500"
            show-word-limit
          ></el-input>
          <h4>附件</h4>
          <ErpUpload
            ref="ErpUpload"
            :uploadParams="uploadParams"
            @uploadList="uploadList"
            @initUpload="initUpload"
            style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
          ></ErpUpload>
        </el-form>
      </div>
    </el-row>
    <el-row style="text-align: center; position: sticky; bottom: 0">
      <el-button type="primary" @click="confirm" :loading="loading">
        确认
      </el-button>
      <el-button @click="$router.back()">取消</el-button>
    </el-row>

    <!-- 唛头弹窗 -->
    <el-dialog
      :visible.sync="showMark"
      title="唛头"
      width="700px"
      append-to-body
      :close-on-click-modal="false"
      modal-append-to-body
    >
      <el-form
        ref="markForm"
        :model="markForm"
        :rules="markFormRules"
        label-position="left"
        label-width="50px"
      >
        <el-form-item label="主唛" prop="mainLabel">
          <el-input
            type="textarea"
            v-model="markForm.mainLabel"
            maxlength="500"
            :rows="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="侧唛" prop="sideMark">
          <el-input
            type="textarea"
            v-model="markForm.sideMark"
            maxlength="500"
            :rows="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="confirmMark">确认</el-button>
          <el-button @click="closeMarkDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 人员选择 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser"></UserChoose>
    <!--产品数量发生变化弹窗  -->
    <CS :isCancel="false" ref="cs" @dialog-confirm="dialogConfirm" />
    <!-- 查看邮件模版示例 -->
    <EmailTemplate
      ref="EmailTemplate"
      :transportWay="form.shippingMethod && form.shippingMethod.toUpperCase()"
    ></EmailTemplate>
  </div>
</template>

<script>
  import { storageFindWarehouseList2 } from '@/api/order'

  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'

  import { mapGetters } from 'vuex'
  import UserChoose from '@/components/userChoose/index'
  import CS from './components/cancel-shipping'
  import Utils from '@/utils/utils'
  import PackingSize from './components/packing-size'
  import popoverShip from './components/popoverShip.vue'
  import { statusListMap } from './helper/data'
  import { mixin } from './helper/mixin'
  import EmailTemplate from '@/components/EmailTemplate/index'
  import { deepCopy, format } from 'kits'

  export default {
    name: 'new-shipment-sheet',
    mixins: [mixin],
    data() {
      return {
        clerkList: [], //单证员设置列表
        spanArr: [],
        pos: 0,
        error: Error,
        orderInfo: {},
        currentPro: {},
        showMark: false,
        isDelivery: true, // 加工出库不需要同时出库
        markForm: {
          mainLabel: '',
          sideMark: '',
        },
        markFormRules: {
          mainLabel: [
            { required: true, message: '请输入主唛', trigger: 'blur' },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
        },
        form: {},
        detailInfo: {}, // batchDetail接口返回详情
        pickerOptions: {}, // 客户交期配置项
        deleteShippingOrderStockIds: [],
        unOutShippingOrderProductIds: [],
        delReturnedNums: [], //
        rules: {
          clerkName: [
            {
              required: true,
              message: '请选择',
            },
          ],
          businessNumber: [{ required: true, message: '请输入订单编号' }],
          shippingMethod: [{ required: true, message: '请选择货运方式' }],
          portShipment: [{ required: true, message: '请输入发货地点' }],
          deliveryDate: [{ required: true, message: '请选择客户交期' }],
          shippingDate: [{ required: true, message: '请选择装运日期' }],
          tradeId: [
            { required: true, message: '请选择贸易条款', trigger: 'change' },
          ],
          shippingMethodId: [
            { required: true, message: '请选择运输方式', trigger: 'change' },
          ],
          // deadlineDate: [{ required: true, message: '请选择最晚交期' }],
          deliveryMode: [{ required: true, message: '请选择交货方式' }],
          deliveryAddress: [{ required: true, message: '请选择物流仓库地址' }],
          arriveDate: [{ required: true, message: '请选择送达时间' }],
          receivingPerson: [{ required: true, message: '请输入收货人' }],
          receivingAddress: [{ required: true, message: '请输入收货地址' }],
          country: [{ required: true, message: '请输入国家' }],
          receivingCompany: [{ required: true, message: '请输入收货公司' }],
          receivingPhone: [{ required: true, message: '请输入联系电话' }],
          domesticLogistics: [{ required: true, message: '请选择国内物流' }],
          trackingNo: [{ required: true, message: '请输入快递单号' }],
        },
        transpotwayList: [], //货运方式
        portShipments: ['义乌', '苏州', '合肥'], //发货地点
        trades: [], //贸易条款
        domesticLogisticsList: [
          //国内物流下拉选项
          {
            code: 0,
            name: '快递',
          },
          {
            code: 1,
            name: '工厂送货',
          },
          {
            code: 2,
            name: '物流自提',
          },
        ],
        advanceFreightList: [
          //垫付运费下拉选项
          {
            code: 0,
            name: '是',
          },
          {
            code: 1,
            name: '否',
          },
        ],
        storageList: [], // 出库仓库
        drawbacks: [
          {
            name: '是',
            code: '1',
          },
          {
            name: '否',
            code: '0',
          },
        ],
        files: '',
        tableKey: Math.random(), //el--table绑定的key值
        showRequired: false,
        ingoreShippingMethodList: [
          'AIR-UPS',
          'AIR-FEDEX',
          'OCEAN-FAST SHIP-UPS',
          'OCEAN-FAST SHIP-FEDEX',
          'OCEAN-STANDARD SHIP-UPS',
          'OCEAN-STANDARD SHIP-FEDEX',
          'EXPRESS-DHL',
          'EXPRESS-UPS',
          'EXPRESS-FEDEX IP',
          'EXPRESS-FEDEX IE',
          'US-FEDEX',
          'US-UPS',
          'EXPRESS-SHUNFENG',
        ],

        canSelectAutoEmail: false,
        loading: false,
      }
    },

    components: {
      UserChoose,
      CS,
      PackingSize,
      popoverShip,
      EmailTemplate,
    },
    filters: {
      stateFilter(val) {
        return statusListMap[val] ? statusListMap[val] : '--'
      },
    },
    watch: {
      'form.shippingMethodId': {
        handler: function (newval, oldval) {
          if (!oldval) return
          let shippingMethod = this.transpotwayList.find((i) => i.id === newval)
          if (shippingMethod) {
            this.form.shippingMethod = shippingMethod.transportWayCn
            this.form.shippingMethodType = shippingMethod.transportType
          } else {
            this.form.shippingMethodId = ''
          }
          if (oldval) {
            this.form.tradeId = ''
          }
        },
      },
      'form.tradeId': function (newval) {
        let trade = this.trades.find((i) => i.amountId === newval)
        if (trade) {
          this.form.trade = trade.amountName
        }
      },
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
      }),
      isAdd() {
        if (!this.$route.query.shipType !== 'Edit') {
          return true
        } else {
          return false
        }
      },
    },
    created() {
      this.getDict()

      // 获取单证员设置数据
      ShipmentMangeInteractor.clerkSettingList().then((res) => {
        if (res && res.code === '000000') {
          this.clerkList = res.data || []
          this.$_initData()
        }
      })
    },

    methods: {
      //查看物流示例
      handleView() {
        this.$refs.EmailTemplate.dialogFormVisible = true
      },
      // 显示隐藏编辑
      toggleEdit(index, flag) {
        const ref = index + 'packingSize'
        this.$refs[ref].toggleEditIcon(flag)
      },
      // 设置产品装箱尺寸
      setSizes(index, sizes) {
        this.form.shippingOrderProductUpdateVOS[
          index
        ].shippingOrderProductBoxVOS = sizes
        this.tableKey = Math.random()
      },
      onShippingMethodChange(val) {
        if (val) {
          let shippingMethod = this.transpotwayList.find((i) => i.id === val)
          if (!shippingMethod) {
            return ''
          }
          const { transportType } = shippingMethod
          const clerkSetting = this.clerkList.find(
            (i) => i.transportType == transportType
          )
          if (!clerkSetting) {
            this.form.clerkName = ''
            this.form.clerkId = ''
          } else {
            this.form.clerkName = `${clerkSetting.clerkNameCn} ${clerkSetting.clerkNameEn}`
            this.form.clerkId = clerkSetting.clerkId
          }
        } else {
          this.form.clerkName = ''
          this.form.clerkId = ''
        }
      },
      // 表格重新合并
      reGetSpanArr(tableData) {
        this.pos = 0
        this.spanArr = []
        this.getSpanArr(tableData)
      },
      getSpanArr(data) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 添加orderProductUniqueId
            if (
              data[i].sku === data[i - 1].sku &&
              data[i].orderProductUniqueId ===
                data[i - 1].orderProductUniqueId &&
              data[i].shippingOrderProductId ===
                data[i - 1].shippingOrderProductId
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      //合并列表格
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex <= 13) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      ckClick(warehouseOutId) {
        let routeData = this.$router.resolve({
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: {
            warehouseOutId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },

      showProDetail(proId) {
        if (proId.sku.indexOf('FL') > -1) return
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: proId.productId,
            editProduct: 1,
            editProductBelonger: '',
          },
        })
        window.open(routeData.href, '_blank')
      },
      showUserTree() {
        this.$refs.UserChoose.showAddEdit('', '', true)
      },
      // 获取字典项
      getDict() {
        // 获取出库仓库下拉框
        storageFindWarehouseList2({
          hasAuth: 1,
          hasCloudWarehouse: 1,
        }).then((res) => {
          this.storageList = res.data
        })
      },

      showUserDialog() {
        let { businessName, businessId } = this.form,
          selectUsers = []
        if (businessName) {
          selectUsers = [
            {
              name: businessName,
              id: businessId,
            },
          ]
        }
        this.$refs['UserChoose'].showAddEdit('shipingOrder', selectUsers)
      },
      chooseUser(users) {
        if (!users || users.length === 0) {
          return
        }
        this.form.clerkId = users[0].id
        this.form.clerkName = users[0].name
      },
      beforeDelete(index) {
        Utils.baseConfirm(this).then(() => {
          this.deletePro(index)
        })
      },

      confirmMark() {
        this.$refs.markForm.validate((res) => {
          if (res) {
            const { mainLabel, sideMark } = this.markForm
            this.currentPro.mainLabel = mainLabel
            this.currentPro.sideMark = sideMark
            this.closeMarkDialog()
          }
        })
      },
      closeMarkDialog() {
        this.showMark = false
      },
      setMark(item) {
        this.currentPro = item
        this.showMark = true
        const { mainLabel, sideMark } = item
        this.markForm.mainLabel = mainLabel
        this.markForm.sideMark = sideMark
      },

      deletePro(index) {
        if (this.form.shippingOrderProductUpdateVOS?.length === 1) {
          return this.$message.warning('至少保留一条产品数据')
        }
        const {
          shippingOrderProductStatus,
          shippingOrderProductStockId,
          shippingOrderProductId,
          warehouseOutNumber,
        } = this.form.shippingOrderProductUpdateVOS[index]
        this.delReturnedNums = this.delReturnedNums || []
        this.delReturnCaseNum = this.delReturnCaseNum || []
        this.delReturnVolume = this.delReturnVolume || []
        this.unOutShippingOrderProductIds =
          this.unOutShippingOrderProductIds || []
        this.delReturnGrossWeight = this.delReturnGrossWeight || []
        // 这里需要判断删除的是否是无库存出运的
        if (shippingOrderProductStatus != 6) {
          this.deleteShippingOrderStockIds.push(shippingOrderProductStockId)
        } else {
          this.unOutShippingOrderProductIds.push(shippingOrderProductId)
        }

        // 删除的产品也计入委托单数量变化
        this.delShippingOrderConfirmReturnDTOS =
          this.delShippingOrderConfirmReturnDTOS || []

        if (shippingOrderProductStatus != 6) {
          this.delShippingOrderConfirmReturnDTOS.push({
            shippingOrderProductStockId,
          })
        }

        let obj = {}
        obj.shippingOrderProductStockId = shippingOrderProductStockId
        obj.shippingOrderProductId = shippingOrderProductId
        obj.warehouseOutNumber = warehouseOutNumber
        obj.returnNum =
          this.form.shippingOrderProductUpdateVOS[index].maxShippNum //取maxShippNum,因为可能是改变数量后在删除的
        obj.returnCaseNum =
          this.form.shippingOrderProductUpdateVOS[index].maxCaseNum
        obj.returnVolume =
          this.form.shippingOrderProductUpdateVOS[index].stockVolume
        obj.returnGrossWeight =
          this.form.shippingOrderProductUpdateVOS[index].stockGrossWeight

        obj.finalNum = 0
        this.delReturnedNums.push(obj)
        this.delReturnCaseNum.push(obj)
        this.delReturnVolume.push(obj)
        this.delReturnGrossWeight.push(obj)
        this.form.shippingOrderProductUpdateVOS.splice(index, 1)
        this.reGetSpanArr(this.form.shippingOrderProductUpdateVOS)
      },
      // 获取产品箱规集合productBoxUpdateDTOS
      $_getProductBoxUpdateDTOS(requestData) {
        const {
          shippingOrderId,
          shippingPlanOrderId,
          planNumber,
          businessNumber,
        } = this.form
        let productBoxUpdateDTOS = []
        // 遍历产品数据添加shippingPlanOrderProductId字段
        requestData.shippingOrderProductUpdateDTOS.map((pro) => {
          // 添加orderProductUniqueId
          if (
            !productBoxUpdateDTOS.find(
              (item) =>
                item.sku === pro.sku &&
                item.orderProductUniqueId === pro.orderProductUniqueId
            )
          ) {
            pro.shippingOrderProductBoxVOS &&
              pro.shippingOrderProductBoxVOS.map((box) => {
                const { boxHeightCm, boxLengthCm, boxWidthCm, sku } = box
                productBoxUpdateDTOS.push({
                  boxHeightCm,
                  boxLengthCm,
                  boxWidthCm,
                  shippingOrderId,
                  shippingPlanOrderId,
                  planNumber,
                  businessNumber,
                  shippingOrderProductId: pro.shippingOrderProductId,
                  sku: pro.sku,
                  // 添加orderProductUniqueId
                  orderProductUniqueId: pro.orderProductUniqueId,
                })
              })
          }
        })

        return productBoxUpdateDTOS
      },
      // 校验装箱尺寸非空
      $_checkPackingSize() {
        let pros = this.form.shippingOrderProductUpdateVOS
        this.showRequired = pros.some((item) => {
          if (
            !item.shippingOrderProductBoxVOS ||
            item.shippingOrderProductBoxVOS.length === 0
          ) {
            return true
          }
          return false
        })
        return this.showRequired
      },
      // 确认修改

      confirm() {
        if (this.$_checkPackingSize()) return
        this.$refs.ruleForm.validate(async (res) => {
          if (!res) {
            return
          }

          // 产品唛头校验
          let products = this.form.shippingOrderProductUpdateVOS
          if (
            !this.columnCheck(products, {
              stockGrossWeight: '总重量不能都为0',
              stockVolume: '总体积不能都为0',
            })
          ) {
            return
          }
          let pro2 = products.find((pro) => !pro.mainLabel)
          if (pro2) {
            return this.$message.warning(
              `产品${pro2.productName}的主唛不能为空`
            )
          }

          this.loading = true

          if (
            this.canSelectAutoEmail &&
            this.form.shippingMethodId &&
            this.ingoreShippingMethodList.includes(
              this.form.shippingMethod.toUpperCase()
            )
          ) {
            this.form.emailAutoFlag = 1
          } else {
            this.form.emailAutoFlag = 0
          }
          let requestData = JSON.parse(JSON.stringify(this.form))
          if (requestData.clerkName) {
            requestData.clerkName = requestData.clerkName.trim()
          }
          // 产品列表字段修改
          requestData.shippingOrderProductUpdateDTOS =
            requestData.shippingOrderProductUpdateVOS
          delete requestData.shippingOrderProductUpdateVOS
          // 设置产品箱规集合
          requestData.productBoxUpdateDTOS =
            this.$_getProductBoxUpdateDTOS(requestData)
          requestData.accessoryUrl = this.files
          requestData.deleteShippingOrderStockIds =
            this.deleteShippingOrderStockIds

          // 判断出运数量有没有发生变化
          let initProData = this.initProData
          let currentProData = this.form.shippingOrderProductUpdateVOS
          let shippingOrderConfirmReturnDTOS = [],
            unOutShippingOrderProductIds = [],
            isChange = false,
            returnedNums = []

          currentProData.forEach((item) => {
            //遍历判断出运数量是否发生变化
            // if (item.shippingOrderProductStatus != '2') {
            let beforeItem = initProData.find(
              (subItem) => subItem.comapreKey === item.comapreKey
            ) // 一定存在
            if (beforeItem.warehouseOutNum !== item.warehouseOutNum) {
              let obj = {}
              isChange = true

              obj.shippingOrderProductStockId = item.shippingOrderProductStockId
              obj.shippingOrderProductId = item.shippingOrderProductId
              obj.warehouseOutNumber = item.warehouseOutNumber
              obj.returnNum = beforeItem.warehouseOutNum - item.warehouseOutNum
              obj.returnCaseNum = beforeItem.stockCaseNum - item.stockCaseNum //退回箱数
              obj.returnVolume = beforeItem.stockVolume - item.stockVolume //退回体积
              obj.returnGrossWeight =
                beforeItem.stockGrossWeight - item.stockGrossWeight //退回重量
              obj.finalNum = item.warehouseOutNum
              if (item.shippingOrderProductStatus == 6) {
                unOutShippingOrderProductIds.push(item.shippingOrderProductId)
              } else {
                shippingOrderConfirmReturnDTOS.push({
                  shippingOrderProductStockId: item.shippingOrderProductStockId,
                })
              }

              returnedNums.push(obj)
              // 将变化的key保存，确定时过滤用
              // changeKeys.push(item.key)
            }
            // }
          })
          unOutShippingOrderProductIds = unOutShippingOrderProductIds.concat(
            this.unOutShippingOrderProductIds
          )

          if (unOutShippingOrderProductIds.length > 0) {
            isChange = true
          }

          // 如果出运数量发生变化
          if (
            this.delShippingOrderConfirmReturnDTOS &&
            this.delShippingOrderConfirmReturnDTOS.length > 0
          ) {
            isChange = true
            shippingOrderConfirmReturnDTOS =
              shippingOrderConfirmReturnDTOS.concat(
                this.delShippingOrderConfirmReturnDTOS
              )
          }
          if (isChange) {
            returnedNums = returnedNums.concat(this.delReturnedNums)

            const res = await ShipmentMangeInteractor.getConfirmUpdate({
              shippingOrderConfirmReturnDTOS,
              unOutShippingOrderProductIds,
            })

            if (res && res.code === '000000') {
              this.$refs.cs.showAddEdit()
              res.data = res.data.map((item, index) => {
                let obj = returnedNums.find(
                  (j) =>
                    j.shippingOrderProductStockId ==
                      item.shippingOrderProductStockId &&
                    j.shippingOrderProductId == item.shippingOrderProductId &&
                    j.warehouseOutNumber == item.warehouseOutNumber
                )
                item.returnNum = obj.returnNum
                item.returnVolume = obj.returnVolume
                item.returnCaseNum = obj.returnCaseNum
                item.returnGrossWeight = obj.returnGrossWeight
                item.finalNum = obj.finalNum
                let { shippingOrderProductLotDTOList, isLotSel } =
                  this._getShippingOrderProductLotDTOList(item)
                item.shippingOrderProductLotDTOList =
                  shippingOrderProductLotDTOList
                item.isLotSel = isLotSel
                // 添加orderProductUniqueId
                item.orderProductUniqueId = item.orderProductUniqueId
                item.usable = '1'
                return item
              })
              this.$refs.cs.cancelForm.tableData = res.data || []
            }
            this.loading = false
          } else {
            const { deliveryDate, shippingDate } = requestData
            if (shippingDate > deliveryDate) {
              this.$message.warning('装运日期不能晚于客户交期')
              return this.loading = false
            }
            requestData.firmFlag = this.detailInfo.firmFlag || 0
            //  console.log('提交', deepCopy(requestData))
            //  return this.loading = false
            const res = await ShipmentMangeInteractor.updateBasic(requestData)

            if (res && res.code === '000000') {
              this.$message.success('操作成功')
              this.$router.back()
            }
            this.loading = false
          }
        })
      },
      _setLotSel(item) {
        // 只有一个批次时，无需选择退回批次
        if (item.shippingOrderProductLotDTOList.length === 1) {
          item.isLotSel = false
          item.shippingOrderProductLotDTOList[0].num = item.returnNum
          item.shippingOrderProductLotDTOList[0].returnNum = item.returnNum
        }
      },

      // 获取批次
      _getShippingOrderProductLotDTOList(item) {
        let tableData = this.initProData
        let isLotSel = true
        // 添加orderProductUniqueId
        let execItem = tableData.find(
          (i) =>
            `${i.shippingOrderProductId}${i.sku}${i.orderProductUniqueId}${i.warehouseOutNumber}` ===
            `${item.shippingOrderProductId}${item.sku}${item.orderProductUniqueId}${item.warehouseOutNumber}`
        )
        if (execItem) {
          item.shippingOrderProductLotDTOList =
            execItem.warehouseInLotArrangeVOList
        } else {
          item.shippingOrderProductLotDTOList = []
        }
        item.shippingOrderProductLotDTOList.forEach((s) => {
          if (!s.maxNum) {
            s.maxNum = s.num
          }
          s.initNum = execItem.warehouseOutNum
          s.lotNumber = s.maxNum
        })

        if (item.shippingOrderProductLotDTOList.length === 1) {
          isLotSel = false
          item.shippingOrderProductLotDTOList[0].num = item.returnNum
          item.shippingOrderProductLotDTOList[0].returnNum = item.returnNum
        }

        return {
          shippingOrderProductLotDTOList: item.shippingOrderProductLotDTOList,
          isLotSel,
        }
      },

      dialogConfirm(list, fileObj) {
        // let requestData = JSON.parse(JSON.stringify(this.form))
        // // 产品列表字段修改
        let requestData = {}
        let baseData = JSON.parse(JSON.stringify(this.form))
        baseData.shippingOrderProductUpdateDTOS =
          baseData.shippingOrderProductUpdateVOS
        delete baseData.shippingOrderProductUpdateVOS
        baseData.accessoryUrl = this.files
        baseData.deleteShippingOrderStockIds = this.deleteShippingOrderStockIds
        baseData.unOutShippingOrderProductIds =
          this.unOutShippingOrderProductIds
        requestData.shippingOrderUpdateDTO = baseData
        // // 设置产品箱规集合
        requestData.shippingOrderUpdateDTO.productBoxUpdateDTOS =
          this.$_getProductBoxUpdateDTOS(baseData)
        requestData.shippingOrderProductUpdateStockDTOs = list
        requestData = {
          ...requestData,
          ...fileObj,
          shippingOrderId: baseData.shippingOrderId,
        }

        ShipmentMangeInteractor.confirmUpdate(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.$router.back()
          }
        })
      },

      $_initData() {
        this.canSelectAutoEmail = false
        ShipmentMangeInteractor.getUpdate(
          this.$route.query.shippingOrderId
        ).then((res) => {
          if (res && res.code === '000000') {
            this.detailInfo = res.data
            let { deadLineDate } = res.data
            if (deadLineDate) {
              this.pickerOptions = {
                // 客户交期不能晚于deadLineDate
                disabledDate(time) {
                  return time.getTime() > new Date(deadLineDate).getTime()
                }
              }
            }
            // 设置产品列表唯一key，判断出运数量变化时用
            // 添加orderProductUniqueId
            this.canSelectAutoEmail = res?.data?.canSelectAutoEmail
            res.data.shippingOrderProductUpdateVOS =
              res.data.shippingOrderProductUpdateVOS.map((item, index) => {
                item.comapreKey = `${item.sku}${item.orderProductUniqueId}${item.warehouseOutNumber}${index}`
                item.maxShippNum = item.warehouseOutNum
                item.maxCaseNum = item.stockCaseNum
                item.maxVolume = item.stockVolume
                item.maxGrossWeight = item.stockGrossWeight
                item.parentProduct = item.parentProduct
                  ? [item.parentProduct]
                  : []

                return item
              })
            res.data.shippingOrderProductUpdateVOS.forEach((item) => {
              item.parentProduct = item.parentProduct.map((ele) => {
                return {
                  ...ele,
                  nameCn: ele.productCn,
                  nameEn: ele.productEn,
                }
              })
            })

            this.form = {
              ...res.data,
            }
            this.getSpanArr(this.form.shippingOrderProductUpdateVOS)
            // 贸易条款tradeList
            ShipmentMangeInteractor.tradeList({
              amountType: 5,
            }).then((res) => {
              if (res && res.code === '000000') {
                // this.trades = res.data
                this.initTrades = res.data
                this.trades = this.initTrades
              }
            })
            this.initProData = JSON.parse(
              JSON.stringify(res.data.shippingOrderProductUpdateVOS)
            )
            res.data.accessoryUrl && this.initUpload(res.data.accessoryUrl)
            // 初始化渲染单证员
            if (!res.data.clerkName) {
              this.onShippingMethodChange(res.data.shippingMethodId)
            }
            res.data.accessoryUrl && (this.files = res.data.accessoryUrl)
          }
        })

        // 获取货运方式
        ShipmentMangeInteractor.transpotwayList().then((res) => {
          if (res && res.code === '000000') {
            this.transpotwayList = res.data.map((i) => {
              i.id = i.id + ''
              return i
            })
          }
        })
      },

      // 返回
      goBack() {
        this.$router.push('/order/shipmentManage/shipmentManage')
      },

      // 总重量，总体积，总箱数保持一致
      onFieldChange(row, field) {
        const pros = this.form.shippingOrderProductUpdateVOS,
          inputValue = row[field],
          { sku, orderProductUniqueId, shippingOrderProductId } = row,
          key = `${sku}${orderProductUniqueId}${shippingOrderProductId}`
        pros.forEach((item) => {
          if (
            `${item.sku}${item.orderProductUniqueId}${item.shippingOrderProductId}` ===
            key
          ) {
            item[field] = inputValue
          }
        })
      },

      // 修改收货地址更新唛头信息
      updateReceivingAddress(_address) {
        const _list = this.form.shippingOrderProductUpdateVOS

        this.form.shippingOrderProductUpdateVOS = _list.map((item) => {
          return {
            ...item,
            mainLabel: this.updateMark(_address, item.mainLabel),
          }
        })
      },
      //黑名单校验
      checkBlack(word) {
        console.log('黑名单校验', word)
      },

      /**
       * 唛头地址数据更新
       * @param {_address} 修改后的收货地址
       * @param {_str} 唛头JSON
       */
      updateMark(_address, _str) {
        if (_str.indexOf('TO:') === -1 || _str.indexOf('Made') === -1) {
          return _str
        } else {
          const _a = [_str.indexOf('TO:') + 3, _str.indexOf('Made')]
          const _s = _str.substring(_a[0], _a[1])

          return _str.replace(_s, _address + '\n')
        }
      },
      // 设置装运日期
      handleSetShippingDate(day) {
        this.form.shippingDate = format(new Date(), day)
      }
    },
  }
</script>

<style scoped lang="scss">
  .required {
    color: red;
  }
  .order-content {
    padding: 0 20px 90px;
    h4 {
      font-family: 'PingFang Bold';
      font-size: 14px;
      margin: 20px 0 10px 0;
    }
    .el-input,
    .el-select {
      width: 100%;
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
  td .link {
    cursor: pointer;
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
  }
  .custom-table tbody td {
    padding: 18px 10px;
  }
  .print-image {
    display: flex;
    // flex-direction: column;
    align-items: center;
  }
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }
  .el-tag-other {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    background-color: #fff7e8;
    border-color: #ffefd1;
    color: #ffae18;
  }
  .selectFlex {
    display: flex;
    align-items: center;
  }
</style>

export const columns = [
  {
    label: '委托单号',
    prop: 'number',
    width: '200',
    isShow: true,
    checkable: false,
  },
  {
    label: '订单编号',
    prop: 'businessNumber',
    width: '200',
    isShow: true,
    checkable: false,
  },
  {
    label: '客户代表',
    prop: 'businessName',
    width: '120',
    isShow: true,
    checkable: false,
  },
  {
    label: '客户名称',
    prop: 'customerName',
    isShow: true,
  },
  {
    label: '贸易条款',
    prop: 'trade',
    isShow: true,
  },
  {
    label: '运输方式',
    prop: 'shippingMethod',
    isShow: true,
  },
  {
    label: '是否退税',
    prop: 'drawbackFlag',
    isShow: false,
  },
  {
    label: '国家',
    prop: 'country',
    isShow: true,
  },
  {
    label: '目的地',
    prop: 'receivingAddress',
    isShow: true,
  },
  {
    label: '发货地点',
    prop: 'portShipment',
    isShow: true,
  },
  {
    label: '总件数',
    prop: 'totalNum',
    isShow: true,
  },
  {
    label: '总箱数',
    prop: 'totalCase',
    isShow: true,
  },
  {
    label: '总毛重',
    prop: 'totalGross',
    isShow: true,
  },
  {
    label: '总体积',
    prop: 'totalVolume',
    isShow: true,
  },
  {
    label: '总计费重KG',
    prop: 'totalChargedWeight',
    isShow: true,
  },
  {
    label: '总运费',
    prop: 'totalFreight',
    isShow: true,
  },
  {
    label: '装运日期',
    prop: 'shippingDate',
    isShow: true,
  },
  {
    label: '客户交期',
    prop: 'deliveryDate',
    isShow: true,
  },
  {
    label: '状态',
    prop: 'status',
    isShow: true,
    checkable: false,
  },
  {
    label: '安排状态',
    prop: 'wmsUpdateCancelFlag',
    isShow: true,
    checkable: true,
  },
  {
    label: '运单号',
    prop: 'shipNo',
    isShow: true,
    checkable: false,
  },
  {
    label: '预计到达时间',
    prop: 'arriveTime',
    isShow: true,
    checkable: false,
    width: 160,
  },
  {
    label: '发货邮件',
    prop: 'mailSend',
    isShow: true,
    checkable: true,
    width: 180
  },
  {
    label: '收货邮件',
    prop: 'signMailSend',
    isShow: true,
    checkable: true,
    width: 180
  },
  {
    label: '已出运时间',
    prop: 'shipTime',
    isShow: true,
    checkable: true,
    width: 180,
  },
  {
    label: '签收时间',
    prop: 'manualSignInDate',
    isShow: true,
  },
  {
    label: '单证员',
    prop: 'clerkName',
    isShow: true,
    width: 200,
  },
  {
    label: '创建人',
    prop: 'creatorName',
    isShow: true,
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
  },
  {
    label: '备注',
    prop: 'remarks',
    isShow: true,
  },
]

export const expressColumns = [
  {
    label: '委托单号',
    prop: 'number',
    width: '200',
    isShow: true,
    checkable: false,
  },
  {
    label: '订单编号',
    prop: 'businessNumber',
    width: '200',
    isShow: true,
    checkable: false,
  },
  {
    label: '客户代表',
    prop: 'businessName',
    width: '120',
    isShow: true,
    checkable: false,
  },
  {
    label: '客户名称',
    prop: 'customerName',
    isShow: true,
  },
  {
    label: '运输方式',
    prop: 'shippingMethod',
    isShow: true,
  },
  {
    label: '是否退税',
    prop: 'drawbackFlag',
    isShow: false,
  },
  {
    label: '国家',
    prop: 'country',
    isShow: true,
  },
  {
    label: '目的地',
    prop: 'receivingAddress',
    isShow: true,
  },
  {
    label: '发货地点',
    prop: 'portShipment',
    isShow: true,
  },
  {
    label: '总件数',
    prop: 'totalNum',
    isShow: true,
  },
  {
    label: '总箱数',
    prop: 'totalCase',
    isShow: true,
  },
  {
    label: '总毛重',
    prop: 'totalGross',
    isShow: true,
  },
  {
    label: '总体积',
    prop: 'totalVolume',
    isShow: true,
  },
  {
    label: '总计费重KG',
    prop: 'totalChargedWeight',
    isShow: true,
  },
  {
    label: '总运费',
    prop: 'totalFreight',
    isShow: true,
  },
  {
    label: '装运日期',
    prop: 'shippingDate',
    isShow: true,
  },
  {
    label: '客户交期',
    prop: 'deliveryDate',
    isShow: true,
  },
  {
    label: '状态',
    prop: 'status',
    isShow: true,
    checkable: false,
  },
  {
    label: '安排状态',
    prop: 'wmsUpdateCancelFlag',
    isShow: true,
    checkable: true,
  },
  {
    label: '运单号',
    prop: 'shipNo',
    isShow: true,
    checkable: false,
  },
  {
    label: '预计到达时间',
    prop: 'arriveTime',
    isShow: true,
    checkable: false,
    width: 160,
  },
  {
    label: '发货邮件',
    prop: 'mailSend',
    isShow: true,
    checkable: true,
    width: 180
  },
  {
    label: '收货邮件',
    prop: 'signMailSend',
    isShow: true,
    checkable: true,
    width: 180

  },
  {
    label: '已出运时间',
    prop: 'shipTime',
    isShow: true,
    checkable: true,
    width: 180,
  },
  {
    label: '签收时间',
    prop: 'manualSignInDate',
    isShow: true,
  },
  {
    label: '单证员',
    prop: 'clerkName',
    isShow: true,
    width: 200,
  },
  {
    label: '创建人',
    prop: 'creatorName',
    isShow: true,
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
  },
  {
    label: '备注',
    prop: 'remarks',
    isShow: true,
  },
]

// 查询出库状态
export const searchStatusList = [
  {
    code: 6,
    name: '未出库',
  },
  {
    code: 0,
    name: '出库中',
  },
  {
    code: 1,
    name: '已出库',
  },
  {
    code: 2,
    name: '出库驳回',
  },
]

export const statusList = {
  9: '未出运',
  5: '已出运',
  3: '已完成',
  4: '已取消',
}

export const statusListArr = [
  {
    code: 9,
    name: '未出运',
  },
  {
    code: 5,
    name: '已出运',
  },
  {
    code: 3,
    name: '已完成',
  },
  {
    code: 4,
    name: '已取消',
  },
]

export const statusListMap = {
  9: '未出运',
  5: '已出运',
  3: '已完成',
  4: '已取消',
  0: '出库中',
  1: '已出库',
  2: '出库驳回',
  6: '未出库',
}
